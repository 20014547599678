import Hero from "../../components/Hero/Hero";
import "./BoardDatesPage.scss";
function BoardDatesPage() {
  return (
    <>
      <Hero title="Board Meeting Dates" />
      <div className="board-dates">
        <p className="board-dates__text">
          {`EACC members are invited to attend meetings of the EACC Board of Directors. As space is limited, it will be on a first come, first served
          basis. Please notify Jodi White at `}
          <a className="board-dates__link" href="mailto:jwhite@tcaconnect.com">
            jwhite@tcaconnect.com
          </a>
          {` if you wish to attend any meeting.`}
        </p>

        <p className="board-dates__text board-dates__text--padding">
          The meeting dates are:
        </p>

        <ul>
          <li className="board-dates__list-item">
            Feb 20, 2025, from 9:30 - 11: 30 am by Zoom and at TCA Offices
          </li>
          <li className="board-dates__list-item">
            March 20, 2025, from 9:30 - 11: 30 am by Zoom and at TCA Offices
          </li>
          <li className="board-dates__list-item">
            May 14, 2025, from 9:30 - 11: 30 am by Zoom and at TCA Offices
          </li>
          <li className="board-dates__list-item">
            AGM - May 29, 2025, from 5:00 - 8:00 pm In Person – Richmond Hill
            Country Club
          </li>
          <li className="board-dates__list-item">
            July 24, 2025, from 9:30 - 11: 30 am by Zoom and at TCA Offices
          </li>
          <li className="board-dates__list-item">
            September 18, 2025 from 9:30 - 11:30 am by Zoom and at TCA Offices
          </li>
          <li className="board-dates__list-item">
            November 20, 2025 from 9:30 - 11:30 am by Zoom and at TCA Offices
          </li>
        </ul>
      </div>
    </>
  );
}
export default BoardDatesPage;
